<template>
    <ion-col>
       <!-- <ion-card :routerLink="'/'+link" routerDirection="none" :disabled="inactivo">-->
       <ion-card >
        <ion-card-header>
            <ion-card-title>
                <small>{{titulo}}</small>
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            {{detalle}}
        </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>
    import {    IonCol,
                IonCard, IonCardHeader,  IonCardTitle, IonCardContent, 
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "TarjetaHome",
            components :  {    
                IonCol,IonCard, IonCardHeader,  IonCardTitle, IonCardContent, 
            }, 
            props: ['link','titulo','detalle','inactivo']
    })
</script>

<style scoped>
        ion-card {
            margin:6px  4px !important;
        }

        ion-card-title{
            text-align: center;
        }
        ion-card-header {
            padding-top: 6px ; 
            padding-bottom : 6px; 
        }
        ion-card-content {
            padding-bottom: 6px;
        }
</style>