<template>
  <ion-app>

    <!-- CONTENIDO NUEVO -->
    <Menu :usr="usuario"  />
        <!-- Aqui incorporaremos el contenido de las navegaciones  -->
        <ion-content id="main-content" scroll-y ="true" >
          <router-view ></router-view>
    </ion-content>
  </ion-app>

</template>

<script>
import { IonApp, IonContent , alertController//, toastController
  } from '@ionic/vue';
import Menu from '@/components/Menu.vue'
import { defineComponent } from 'vue';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  data() {
    return {
      usuario: this.$current_user_name, 
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: {
    IonApp, Menu, IonContent
  }, 
   methods: {
    async openIosAlert() {
      const ancho_imagen = 1072
      const largo_imagen = 1775 
      const ancho_screen = window.screen.width
      const ancho = parseInt(ancho_screen*0.85)
      const alto = parseInt(largo_imagen*ancho/ancho_imagen)
      window.miconsola(alto,ancho)
      const alerta = await alertController
        .create({
          cssClass: 'my-custom-class',
          message: `<img src="assets/tool-tip-install-ios.png" style="width:${ancho} !important; height:${alto} !important; border-radius: 2px">`,
          mode : "md",
          buttons: [
            {
              text: 'Ok',
              handler: () => { console.log('Confirm Okay')},
            },
          ]
        })
      return alerta.present();
    }, 
    async openInstallPromotion() {
      const toast = await alertController
        .create({
          header: 'Instala la aplicación ',
          message: 'Irá mucho más rápida y la tendrás accesible desde tu pantalla principal sin necesidad de entrar en Chrome.',
          position: 'middle',
          buttons: [
            {
              text: 'Si',
              //side: 'end',
              icon: 'checkmark-circle-sharp',
              handler: () => {
                console.log('Favorite clicked');
                this.intentos+=1
                this.install();
                toast.dismiss()
              }
            }, {
              text: 'No',
              //side: 'end',
              icon: 'close-circle-sharp',					
              role: 'cancel',
              handler: () => {
                this.dismiss()
                console.log('Cancel clicked');
              }
            }
          ]
        })
      return toast.present();
   },
    async dismiss() {
      console.log('El usuario no ha querido ')
      this.deferredPrompt = null;
    },
    async install() {
      console.log('Instalando.... ')
      this.deferredPrompt.prompt();
    }
  },
  mounted() {
     eventBus().emitter.on('change-user', (usr) => {
          this.usuario = usr.nombre ;
        });
    /* Deteccion de IOS y propuesta de que el usuario siga pasos de instalación  */
        // Detects if device is on iOS 
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test( userAgent );
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
          this.openIosAlert()
        }
  }, 
  created(){
      window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          if (this.intentos==0) {this.openInstallPromotion()}
        });
      window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });
  }
});
</script>
<style>
  .my-custom-class .alert-message{
    max-height: 700px !important;
  }
  
  .my-custom-class .alert-wrapper {
    --max-width: 90%;
    --width: 90%;
    --max-height: 80%;
    --height: 80%;
    }
  
</style>