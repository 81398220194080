<template>
   <ion-page >
        <CabeceraComponent titulo="Resumen de situacion"/>

        <ion-content  v-if="showme" >


            <ion-toolbar class="separador" >
                <ion-title class="separador" >Visitas</ion-title>
                <ion-buttons slot="end" color="primary" class = "detail" size="small">
                    <ion-button class="mini-boton" size="small" fill="solid" color="darkdanger" @click="irVisitas('pendientes')">
                            <small>Pendientes</small>        
                    </ion-button>
                    <ion-button class="mini-boton"  size="small" fill="solid" color="darksuccess" @click="irVisitas('hechas')">
                            <small>Realizadas</small>
                    </ion-button>
                    <ion-button class="mini-boton"  size="small" fill="solid" color="primary"  @click="irVisitas('todas')">
                        <small>Todas</small>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>        

            <ion-row class="detalle" v-if="sales">
              <ion-card-content>
                <ul>    
                    <li>
                        <ion-label>
                            <small>Comercial: {{$current_user_name}}</small>
                        </ion-label>
                    </li>
                    <li>
                        <ion-label>
                            <small>Pdv: {{mipdv}}</small>
                        </ion-label>
                    </li>
                    <li>
                        <ion-label>
                            <small>Nifs activos Ltm: {{clientes_ltm}} ({{ventas_ltm}} de venta) </small>
                        </ion-label>
                    </li>
                </ul>
                </ion-card-content>   
            </ion-row>

            <BarChartComponent :data="bchartdata" v-if="showBar" :clave="'barchart-graph:'+$current_user" />
            
            <ion-toolbar class="separador" >
                <ion-title class="separador" >Resultados ultimos 4 meses</ion-title>
            </ion-toolbar>    
            
            <!-- Resultados del comercial-->
            <ion-grid>            
                <ion-row class="tarjetas" padding-top padding-bottom margin-top margin-bottom v-if="sales">
                    <TarjetaVisitas :detalle="numero(sE['q0v'])" titulo="Ventas" v-if="sales"/>
                    <TarjetaVisitas :detalle="numero(sE['q0m'])" titulo="Margen" v-if="sales"/>
                    <TarjetaVisitas :detalle="entero(sE['q0a'])" titulo="Clientes" v-if="sales"/>
                </ion-row>
            </ion-grid>

            <!-- Comparacion de resultados con el resto--> 
            <ion-toolbar class="separador"  v-if="sales">
                <ion-title class="separador" >Comparacion (Pdv, Zona, GES) </ion-title>
            </ion-toolbar>    
            <ion-grid  fixed="true" :key="Usuario">
                    <!-- ventas-->
                    <LineChartRunComponent unidades ='miles' valor='q0v'  
                        :clave="'sales-graph:'+Usuario" 
                        v-if="sales"
                        divisor='q0e' titulo="Ventas" :data="lchartdata"/>
                        
                    <!-- Margen -->
                        <LineChartRunComponent unidades ='miles' valor='q0m'  
                            :clave="'margin-graph:'+$current_user" 
                            v-if="sales"
                            divisor='q0e' titulo="Margen"  :data="lchartdata"/>

                    <!-- Clientes -->
                            <LineChartRunComponent unidades ='euros' valor='q0a'  
                            :clave="'customers-graph:'+$current_user" 
                            v-if="sales"
                            divisor='q0e' titulo="Clientes"  :data="lchartdata"/>

            </ion-grid>    

        </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonGrid, IonButtons, IonContent, 
                IonTitle, IonToolbar ,
                IonButton, IonRow, 
                IonLabel, 

                  
                IonCardContent, 

                } from '@ionic/vue';
    import TarjetaVisitas from '@/components/TarjetaVisitas'
    import LineChartRunComponent from '@/components/LineChartRunComponent'
    import BarChartComponent from '@/components/BarChartComponent'
    import CabeceraComponent from '@/components/CabeceraComponent'
    import * as _ from 'underscore';
    import { defineComponent } from 'vue';

    export default defineComponent({
            name : "Home",
            components: {  IonPage, IonGrid,  IonButtons, IonContent, 
                 IonTitle, IonToolbar, 
                 IonLabel,  
                   
                IonCardContent, 
                TarjetaVisitas, BarChartComponent, LineChartRunComponent, CabeceraComponent, 
                 IonButton,  IonRow
            }, 
            computed :{
                SummaryVisitsUrl: function () {
                    return `/month_summary/${this.$current_user}`
                    }, 
                SummarySalesUrl : function () {
                    return `/sales_stats/${this.$current_user}`
                    }, 
                Usuario: function() {
                    return this.$current_user
                }
            },
            mounted() {
                this.fetchSalesSummary()
                this.fetchVisitsSummary()
                this.showme = true ;
                window.miconsola(window.screen.width)
            },
            methods : {
                async irVisitas(modo) {
                    this.$router.push('/visitas/'+modo)
                },
                async fetchVisitsSummary(){ 
                    window.miconsola(this.SummaryVisitsUrl)
                    const result =  await this.$http.get(this.SummaryVisitsUrl) 
                    const datos = result.data
                    window.miconsola(JSON.stringify(datos))
                    this.bchartdata = _.extend({},datos)
                    const self = this
                    setTimeout(function(){self.showBar = true},200)
                    //this.showBar = true
                }, 
                 async fetchSalesSummary(){ 
                    const result = await this.$http.get(this.SummarySalesUrl) 
                    const datos = result.data
                    this.clientes_ltm = this.entero(datos._id.lta)
                    this.ventas_ltm = this.numero(datos._id.ltv)
                    this.mipdv = datos.pdv.nom
                    window.miconsola(JSON.stringify(datos).slice(0,100))
                    this.lchartdata = _.extend({},datos)
                    this.sE= _.extend({},datos._id)
                    this.sales= true
                    }, 
                numero (n){
                    return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n/1000).toLocaleString()+' k€'
                }, 
                entero (n){
                     return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString()
                }
                
            },
            data () {
                return {
                        showme :false, 
                        showBar: false,
                        sE: undefined,
                        bchartdata :undefined, 
                        lchartdata: undefined,
                        sales: false , 
                        clientes_ltm : undefined, 
                        ventas_ltm : undefined, 
                        mipdv : undefined 
                }
            }, 

})
</script>

<style scoped>
        ion-card-content {
            padding: 0px !important;
        }
        ion-row.detalle {
            width: 90% !important;
            margin: auto;
            background-color: #fafafa !important;
            color: var(--ion-color-primary);
            font-weight: 550 !important;  
            border: 1px solid var(--ion-color-light) !important;


        }
        ul {
            margin-top: 6px !important;            
            margin-bottom: 6px !important;            
        }
        ion-title {
            width: 100% !important;
            padding-left: 12px !important;
            padding-right: 12px !important;
        }
        LineChartRunComponent {
            width: 100%;
        }
        ion-button.mini-boton {
            font-size: x-small; 
            height: 24px;
            font-weight:bold;
        }
        ion-buttons.detail {
            width: 50%
        }
        ion-button{
            width: 32%;
        }
        TarjetaVisitas {
            width: 20%;
        }
        .derecha {
            text-align: right !important;
        }
        .con_padding {
            padding-right: 8px !important;
        }
        ion-toolbar.separador {
            width: 96%;
            padding-top: 10px !important;    
            margin-left: 2% !important;
            margin-bottom: 10px;
            border-bottom: 2px solid var(--ion-color-light);
            --min-height : 36px !important; 
        }
        ion-title.separador {
            padding-left: 16px;
            padding-right: 16px;
            width: 90% !important;
            text-align: left;
            color:var(--ion-color-primary);
        }
        ion-note{
            color:whitesmoke;
        }
        ion-row.tarjetas {
            width:90%;
            margin: auto; 
        }

</style>