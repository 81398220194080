<template>
    <ion-col>
        <!--<ion-card :routerLink="'/'+link" routerDirection="none" :disabled="inactivo">-->
        <ion-card>
            <ion-card-header v-if="titulo.length>0 && titulo!=undefined">
                <ion-card-subtitle size="small"  :class="izq"
                    v-if="titulo.length>0 && titulo!=undefined">
                {{titulo}} 
                </ion-card-subtitle>
            </ion-card-header>

            <ion-card-content :class="izq" v-if="detalle.length>0 && detalle!=undefined">
                {{detalle}}
            </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>
    import {    IonCol,
                IonCard, IonCardHeader, 
                IonCardSubtitle,
                IonCardContent, 
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "TarjetaVisitas",
            components :  {    
                IonCol,IonCard, IonCardHeader,  
                IonCardSubtitle,
                IonCardContent, 
            }, 
            data() {
                return {
                    izq: this.izquierda==true ? 'izquierda' : 'derecha'
                }
            },
            //props: ['link','titulo','detalle','inactivo','izquierda']
            props: ['titulo','detalle','izquierda']
    })
</script>

<style scoped>
        ion-col { padding: 4px;}
        ion-card {
            margin:6px  2px !important;
            
        }
        ion-card-content {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 4px; 
            padding-right: 12px;
            text-align: right; 
        }
        ion-card-content.derecha {
            text-align: right; 
        }
        ion-card-content.izquierda {
            padding-left:8px;
            text-align: left; 
        }
        
        ion-card-subtitle{
            text-align: center;
        }
        ion-card-header {
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px ; 
            padding-bottom : 4px; 
        }
</style>