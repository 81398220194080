<template> 
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header lines="none">Sales<span><sup>+</sup></span></ion-list-header>
            <ion-note>Vende mas, y mas y mas...</ion-note>
            
            <br/>
              <ion-item @click="logout()">
                  <ion-label class="nombre">{{who}}</ion-label>
                  <ion-button color="darkdanger" slot="end">Salir</ion-button>
              </ion-item>

            <!--<hr/>-->
              <ion-item class="nombre-secundario" lines="none">
                  <ion-label class="nombre-secundario">
                    <small>Conectado como: {{usr_name}}</small>
                  </ion-label>
              </ion-item>
            <br/>
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" 
                :router-link="p.url" lines="none" detail="false" class="hydrated" 
                :class="{ selected: selectedIndex === i }"
                v-if="showMembers(p.url)"
                >
                  <ion-icon :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
        </ion-content>
      </ion-menu>
</template>

<script>
import { 
  IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, 
  IonMenu, IonMenuToggle, IonNote,menuController 
  } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { people, home, closeCircle,checkmarkDoneCircle, apps, logOutOutline, statsChartOutline } from 'ionicons/icons';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  components: {
    IonButton, 
    IonContent,  IonIcon,  IonItem, IonLabel, IonList,IonListHeader,  IonMenu, 
    IonMenuToggle, IonNote 
  },
  mounted() {
        window.miconsola('Menu mounted')
        eventBus().emitter.on('set-user', (uname) => {
          window.miconsola('recibo set-user en Menu  '+JSON.stringify(uname))
          this.usr_name= uname
        });
        eventBus().emitter.on('user-logged', (dat) => {
          window.miconsola('recibo user-logged en Menu  '+JSON.stringify(dat))
          this.who= dat.who
          this.supervisor= dat.supervisor
        });

  }, 
  data() {
    return {
      usr_name:undefined, 
      who: undefined, 
      supervisor: undefined
    }
  },
  methods: {
    async logout() { 
       this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.NONE)
       this.$firebase.auth().signOut()
        .then(function() {
          alert('vas a cerrar la aplicación!!')
          menuController.close()
          window.close()
        })
        .catch(function(error) {
          window.miconsola(JSON.stringify(error))
          alert('algo ha fallado....')
          });
    },
    showMembers(url){
      return url!=='/members' ? true : this.supervisor // this.$supervisor 
    }
  },

  setup() {
    window.miconsola('Menu setup')
    const selectedIndex = ref (0);
    const appPages = [      
      {
        title: 'Members',
        url: '/members',
        iosIcon: people,
        mdIcon: people
      },
      {
        title: 'Resumen del comercial',
        url: '/home',
        iosIcon: home,
        mdIcon: home, 
        show: true
      },
      {
        title: 'Visitas pendientes',
        url: '/visitas/pendientes',
        iosIcon:  closeCircle,
        mdIcon:  closeCircle, 
        show: true
      },
      {
        title: 'Visitas realizadas',
        url: '/visitas/hechas',
        iosIcon: checkmarkDoneCircle,
        mdIcon: checkmarkDoneCircle, 
        show: true  
      }
      ,
      {
        title: 'Todas la visitas',
        url: '/visitas/todas',
        iosIcon: apps,
        mdIcon: apps, 
        show: true
      },
      {
        title: 'Listados excel',
        url: '/reporting',
        iosIcon: statsChartOutline,
        mdIcon: statsChartOutline
      }
      
    ];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      checkmarkDoneCircle, 
      closeCircle,
      people,
      home, 
      apps,
      selectedIndex,
      appPages,logOutOutline,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>
  .nombre {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
  .nombre-secundario {
    color: var(--ion-color-primary);
    font-style: italic;
    font-weight: bold;
    border-bottom:none;

  }
  ion-label { padding-left : 8px;}
  ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }

  ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  ion-menu.md ion-list {
    padding: 20px 0;
  }

  ion-menu.md ion-note {
    margin-bottom: 30px;
    padding-left: 10px;
  }

  ion-menu.md ion-list-header,
  /*ion-menu.md ion-note {
    padding-left: 10px;
  }*/

  ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }

  ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;

    min-height: 20px;
  }

  ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
  }

  ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
  }

  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }

  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu.md ion-item ion-icon {
    color: #616e7e;
  }

  ion-menu.md ion-item ion-label {
    font-weight: 500;
  }

  ion-menu.ios ion-content {
    --padding-bottom: 20px;
  }

  ion-menu.ios ion-list {
    padding: 20px 0 0 0;
  }

  ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
  }

  ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
  }

  ion-menu.ios ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
  }

  ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
  }

  ion-menu.ios ion-list-header,
  ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
  }

  ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }
</style>