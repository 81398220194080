import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import VueGoogleCharts from 'vue-google-charts'
import eventBus from "@/event-bus";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

window.miconsola =  function() {void(0)} //console.log 
window.fake_console =   function() {void(0)}

/** VARIABLES GLOBALS DEL PROYECTO, CARGAN UNA ÚNICA VEZ EN MEMORIA */
axios.defaults.baseURL = 'https://sales-plus.ges.services'
//axios.defaults.baseURL = 'http://localhost:3000'

axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
//axios.defaults.headers['Access-Control-Request-Method'] = "GET, POST, OPTIONS, PUT, PATCH, DELETE"
//axios.mode = "no-cors"

/** FB config*/
firebase.initializeApp({
  apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
  authDomain: "appgescom.firebaseapp.com",
  projectId: "appgescom",
  storageBucket: "appgescom.appspot.com",
  messagingSenderId: "728244704694",
  appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
  measurementId: "G-KX4GNXBZ0Q",
  databaseURL: "https://appgescom.firebaseio.com"
});

/** 
   * Carga unica de la lista de clientes 
   * Se ejecuta cuando hay cambio de usuario 
   * */

  async  function init_data(usuario)
  { 
    const clientesurl = `/clientes/${usuario}`
    window.miconsola(clientesurl)
    const scData = await self.$http.get(clientesurl)
    self.$clientes = scData.data.reduce((m,v)=>{ m[v['c']] = v; return m;} , {})
    window.miconsola(self.$clientes)
    self.$nifs = scData.data.reduce((m,v)=>{ m[v['nf']] = v['nf']+'-'+v['n']; return m;} , {})
    window.miconsola(self.$nifs)
  }


/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(VueGoogleCharts)
          .use(router);

/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$current_user = undefined
self.$current_user_name = undefined
// nombre usuario que aparece en el menu (el que se conecta)
self.$init_data = init_data


/**
 * Variable global para acceder a Firebase 
 * desde el conjunto de la aplicación 
 * sino acaba intentando instanciarla varias veces
 */
self.$firebase = firebase
window.miconsola('RUTAS : '+JSON.stringify(self.$router.routes))
/** Logica de la aplicación */
firebase.auth().onAuthStateChanged(async (usr) => {
   /** Aqui el nombre del usuario esta a primer nivel, 
    *  Firebase devuelve el objetvo 'user' 
    */
   window.miconsola(usr)
   window.miconsola('paso por la función onAuthStateChanged')
   if(usr!=null) {
    /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
    window.miconsola('if/main 110: '+usr.displayName)
    firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
      
      self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
      const supRequest = await self.$http.get('/check')
      const roles = supRequest.data
      window.miconsola(roles)
      if ( roles.supervisor === true) {
        window.miconsola('if/main 116: Soy supervisor')
        eventBus().emitter.emit('set-home', 'members')
        self.$supervisor = true
        window.miconsola('Main 121 : '+JSON.stringify({'who':'-->'+usr.displayName, 'supervisor': true}))
        eventBus().emitter.emit('user-logged',{'who':usr.displayName, 'supervisor': true});   
        window.miconsola('RUTAS 121: '+JSON.stringify(self.$router.routes))
        self.$router.replace('members')
      }
      else {
        window.miconsola('if/main 124: NO soy supervisor')
        self.$current_user = roles.usr
        self.$current_user_name = roles.uname
        init_data(roles.usr)
        self.$supervisor = false
        eventBus().emitter.emit('set-home', 'home')
        eventBus().emitter.emit('user-logged',{'who':usr.displayName, 'supervisor': false});
        eventBus().emitter.emit('set-user',roles.uname);
        window.miconsola('RUTAS 133: '+JSON.stringify(self.$router.routes))
        self.$router.replace('home')
      }
    })
   }
   else {
     /** no hay usuario, pasamos por login */
     window.miconsola('No hay usuario, pasamos por pagina de login')
     self.$router.replace('login')
   }
});

router.isReady().then(() => {
  window.miconsola('App montada')
  app.mount('#app');
  
});


/**
 * Listener para los cambios de comercial cuando eres super-user
 */
eventBus().emitter.on('change-user', (usr) => {
  window.miconsola('cambiando el nombre ..... ')
  self.$current_user = usr._id
  self.$current_user_name = usr.nombre
  window.miconsola(self.$current_user)
  init_data(usr._id)      
});
