import { createRouter, createWebHistory } from '@ionic/vue-router';

import Home from '@/pages/Home.vue'
import VisitsList from '@/pages/VisitsList.vue'
import MembersList from '@/pages/MembersList.vue'
import Reporting from '@/pages/Reporting.vue'
import Login from '@/pages/Login.vue'
import firebase from 'firebase/app';
import 'firebase/auth';

const routes = [
  {
    path: '/',
    component: () => import ('@/pages/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  
  {
    path: '/members',
    name: 'Members',
    component: MembersList, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/visitas',
    name: 'Visitas',
    component: VisitsList, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/visitas/:estado',
    name: 'Visitas',
    component: VisitsList, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/visita/:visitId',
    component: () => import ('@/pages/VisitReport.vue'), 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting, 
    meta: {
      requiresAuth: true
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/** miramos en casda caso si estamos locados con algún usuario  */
router.beforeEach((to, from, next) => {
  window.miconsola(from,to,next)
  const currentUser = firebase.auth().currentUser;
  window.miconsola('index86 : en el router desde: '+from.path+' hacia : '+to.path) 
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    window.miconsola('Redireccion a login, intento pagina y no hay credencial')
    next('/');
  } 
  else {
    window.miconsola(' cualquier otro caso ')
    next();
  }
});
export default router
