<template>
    <ion-page>
        <CabeceraComponent titulo="Miembros del programa"/>
        <ion-content class="ion-padding">
                <ion-toolbar>
                    <ion-searchbar  :value="search" debounce="100" 
                        @ionChange="lanzarBusqueda($event)"
                        @ionClear="lanzarBusqueda($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        :placeholder="'Filtrar comerciales'" 
                        mode = "md">
                    </ion-searchbar>
                </ion-toolbar>
            <ion-grid>
                <ion-list lines="full" mode="md" v-if="showme">
                    <ion-item v-for="(comercial,index) in filtrados" v-bind:key="index" >
                        <ion-grid class="sin-borde"  @click="setSalesRep(comercial)">
                            <ion-row class="elemento ion-align-items-start" >
                                <ion-row>
                                   <h4 class="nombre_cortado"><small>{{comercial['nombre']}}</small></h4>
                                </ion-row>
                                <ion-row>
                                    <p><small>{{comercial['zona']}} ( Pdv: {{comercial['pdv']}} )</small></p>    
                                </ion-row>  
                            </ion-row>
                        </ion-grid>  
                    </ion-item>
                </ion-list>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>



<script>
    import { IonContent, IonSearchbar,
             IonGrid,  IonRow,
            IonPage, IonToolbar,
            IonList, IonItem
        } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import eventBus from "@/event-bus";
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';

    export default defineComponent({
        name: 'MembersList',
        components: { 
            IonContent, IonSearchbar,
             IonGrid,  IonRow,IonToolbar,
            IonPage, 
            IonList, IonItem, CabeceraComponent
            }, 
        data() {
            return {
                showme :false, 
                search: [], 
                datos: [], 
                filtrados:[], 
                tipo: 'todos', 
                supervisor : true
            }
        }, 
        computed :{
                MembersUrl: function () {
                    return `${this.$main_url}/members`
                    }, 
            },  
        mounted() {
            /** Solo puedes verla si eres supervisor, sino te redirige a  */
            if (this.$supervisor === true) {
                this.fetchData()
                this.showme = true
            }
            else  {
                alert('No puedes ver esta pagina, te redirigimos a tu home ')
                this.$router.replace('home')
            }
        },
        methods : {
            async fetchData(){ 
                const result = await this.$http.get('/members') 
                const data = result.data
                window.miconsola(data)
                this.datos = _.sortBy(data, (e)=>  {return e._id})
                window.miconsola(this.datos)
                this.filtrados = this.datos
            },
            async filterData(termino){
                    this.filtrados = 
                        (termino !== '' && termino !== undefined &&  termino.length>0 )
                            ? _.filter(this.datos, (e)=> {return JSON.stringify(e).toLowerCase().indexOf(termino.toLowerCase()) !==-1 })
                            : this.datos
            }, 
            lanzarBusqueda(ev){
                    const termino =ev.target.value 
                    if (termino.length>=3) {this.filterData(termino)}   
            },
            setSalesRep(member){
                window.miconsola(member)
                eventBus().emitter.emit('change-user',member);
                eventBus().emitter.emit('set-user',member.nombre);
                window.miconsola(this.$current_user)
                this.$router.push('/home')
            } 
        }
        });
</script>

<style scoped>
    ion-list{
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 12px;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    ion-item {
        --inner-padding-end: 4px !important; 
        --padding-start: 10px !important; 
    }
    ion-row.elemento {
        margin: 10px 0px !important;
    }
   
    ion-grid.sin-borde{
        padding: 0px !important; 
        margin: 0px !important; 
        width: 100% !important;
    }
    span.grande { font-size:larger }
    h4 { margin:0px !important; }
    h3.derecha { text-align: right !important; }
    h5,h6,p {
        margin-top:0px !important;
        margin-bottom:0px !important;
    }
    ion-col.nombre { padding-top:0px !important; }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
     ion-img.etiqueta{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 2;
        max-width: 60px;
        max-height: 60px;
    }
</style>
