<template>
    <ion-page>

          <ion-content>
            <ion-grid class="google">              
              <ion-row class="google ion-align-items-center ion-justify-content-center">
                <ion-button v-on:click="loginWithGoogle" fill="outline" size ="large" color="primary" class="login">
                    <ion-icon slot="start" icon="assets/google.svg"/>
                    Login 
                </ion-button>
              </ion-row>
            </ion-grid>
          </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonContent, IonButton,
                IonGrid,IonRow, IonIcon

            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus";
    export default defineComponent({
        name : "Login",
        components: {  IonPage, IonContent, IonButton,
            IonGrid,IonRow, IonIcon
            },
        data() {
            return {
                supervisor : false
            }
        },
        methods: {
            async loginWithGoogle() {
                const self = this
                this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL)
                    .then(function() {
                        const provider = new self.$firebase.auth.GoogleAuthProvider();
                        provider.setCustomParameters({
                            prompt: 'select_account'
                        });
                        self.$firebase
                            .auth()
                            .signInWithPopup(provider)
                            .then( (usr) => {
                                /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
                                window.miconsola('Lg 48: '+JSON.stringify(usr.user.email))
                                self.$firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
                                    
                                    self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
                                    const supRequest = await self.$http.get('/check')
                                    const roles = supRequest.data
                                    window.miconsola(JSON.stringify(roles))
                                    if ( roles.supervisor===true) {
                                        window.miconsola('Lg 56: '+JSON.stringify(usr.user.email))
                                        window.miconsola(JSON.stringify({'who':usr.user.displayName, 'supervisor': true}))
                                        self.$supervisor = true;
                                        eventBus().emitter.emit('user-logged',{'who':usr.user.displayName, 'supervisor': true});
                                        eventBus().emitter.emit('set-home', 'memebers')
                                        self.$router.replace('members');
                                    }
                                    else {
                                        window.miconsola('Login 66: '+JSON.stringify(usr.user.email))
                                        window.miconsola('No soy supervisor,emito user-logged y voy a home  ')
                                        window.miconsola(roles)
                                        window.miconsola('Login/70 : '+JSON.stringify({'who':usr.displayName, 'supervisor': false}))
                                        self.$current_user = roles.usr
                                        self.$current_user_name = roles.uname
                                        eventBus().emitter.emit('set-user', roles.uname);
                                        eventBus().emitter.emit('user-logged',{'who':usr.user.displayName, 'supervisor': false});
                                        self.$init_data(roles.usr)
                                        self.$supervisor = false;
                                        eventBus().emitter.emit('set-home', 'home')
                                        self.$router.replace('home');
                                    }
                                })
                            })
                            .catch(err => {
                                // TODO:
                                alert(err.message);
                            });


                    })
                    .catch(function(error){
                        window.miconsola('Lg 83 (Error) : ',JSON.stringify(error))
                    })
                


                
                
            }
        }
})

</script>

<style scoped>
    ion-grid.google {
        height: 100%;
        width: 90%;
    }
    ion-row.google {
        height: 100%;
    }
</style>