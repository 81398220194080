<template>
    <ion-row class="gchart-cont">
       <GChart
            style="height: 150px  !important;"
            type="BarChart"
            v-bind:key="clave"
            :data="chartData"
            :options="chartOptions"
            v-if="showchart"
        /> 
    </ion-row>      
</template>

<script>
    import {  IonRow } from '@ionic/vue';
    import { GChart } from 'vue-google-charts'
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "LineChartRunComponent",
            components: { GChart, IonRow}, 
            props: {data: Object, clave:String},
            mounted() {
                var self = this
                const yo = this.data['yo']['actual']
                const ges = this.data['ges']['actual']
                try {
                    this.chartData = [
                        ['Version', 'Realizadas', 'No Conseguidas', 'Pendientes', { role: 'annotation' }],
                        ['yo', yo.done||0, yo.fail||0, yo.totales-(yo.done||0)-(yo.fail||0),`%${parseInt(yo.done*100/yo.totales)} realizadas`],
                        ['Objetivo', yo.teoricas||0, 0, yo.totales-yo.teoricas||0,`%${parseInt((yo.teoricas||0)*100/yo.totales)} objetivo`],
                        ['Ges', ges.done||0, ges.fail||0, ges.totales-(ges.done||0)-(ges.fail||0),`%${parseInt(ges.done*100/ges.totales)} realizadas`]]
                setTimeout(function(){ self.showchart=true }, 750)   
                }
                catch(ex){
                    this.chartData = undefined
                }
                             
            },
            data() {
                return {
                    chartData: undefined, 
                    showchart: false, 
                    chartOptions: {
                        legend: { position: 'top', maxLines: 1 ,textStyle: {color: '#003DA5', fontSize: 9}},
                        chartArea:{'width':'70%','left':'20%' }, 
                        backgroundColor: { fill:'transparent' },
                        bar: { groupWidth: '60%' },
                        isStacked: 'percent', 
                        width : '90%', heigth: '20%', 
                        vAxis: {textStyle:{ fontSize: 10,color: '#003DA5',opacity: 0.8}},
                        hAxis: { minValue: 0,maxValue: 1 ,ticks: [0,  1],textStyle: {color: '#003DA5', fontSize: 9}},
                        colors : ['#003DA5', '#009FDF','#ffc409'], 
                        annotations: {textStyle: { color: '#003DA5', fontSize: 10,bold: true,italic: true, opacity: 0.8}}
                    }
                }
            }
})
</script>

<style scoped>
    ion-row.gchart-cont {
        justify-content: center; 
        align-items: center;
        margin: auto; 
    }
</style>    