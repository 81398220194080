<template>
    <ion-page v-bind:key="$current_user+'_'+estado">
        <!-- 
            <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-menu-button />
                </ion-buttons>
                <ion-title>Plan de visitas Mensual</ion-title>
            </ion-toolbar>
        </ion-header>
        -->
        <CabeceraComponent titulo="Plan de visitas actual"/>


        <ion-content class="ion-padding">
            <ion-grid>
                <p>{{$current_user_name}}</p>
                <ion-row padding-top padding-bottom margin-top margin-bottom>
                    <ion-segment v-bind:value="tipo"  @ionChange="segmentChanged($event)" color="primary" mode="ios"  >
                        <ion-segment-button value="Semanal">
                            <ion-label>Semanal</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="Quincenal">
                            <ion-label>Quincenal</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="Mensual">
                            <ion-label>Mensual</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="todos">
                            <ion-label>Todos</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </ion-row>

                <!-- Spinner temporal  de carga -->
                <ion-row  v-if="!showme || filtrados==undefined " 
                    class="spin ion-justify-content-center"  >
                    <ion-col  v-if="!showme || filtrados==undefined " 
                        class="spin ion-align-self-center" size="12" > 
                        <ion-spinner color="primary" duration="90000"
                            v-if="!showme || filtrados==undefined ">
                        </ion-spinner>
                    </ion-col>
                </ion-row>

                <!-- Caso sin datos -->
                <ion-list lines="full" mode="md" 
                    v-if="showme && filtrados!=undefined && filtrados.length==0">
                    <ion-card>
                        <ion-card-title>
                            Sin datos...
                        </ion-card-title>
                        <ion-card-content>
                            No hay visitas con este criterio 
                        </ion-card-content>    
                    </ion-card>
                </ion-list>
                <ion-list lines="full" mode="md" v-if="showme  && filtrados!=undefined && filtrados.length!=0">
                    <!--:disabled ="cliente.lock"-->
                    <ion-item v-for="(cliente,index) in filtrados"  
                     :routerLink="'/visita/'+cliente['_id']" routerDirection="none"
                     v-bind:key="index" 
                     >
                        <ion-img src="assets/marca_realizada.png" v-if="cliente.lock " class="etiqueta"/>
                        <ion-grid class="sin-borde">
                            <ion-row class="elemento ion-align-items-start" >

                                <ion-row>
                                    <ion-col size="1">
                                        <ion-badge :color="cliente.badge">
                                            <small>{{cliente.visitas.slice(0,1)}}</small> 
                                            </ion-badge>
                                    </ion-col>
                                    <ion-col>
                                        <!--<h4 class="nombre_cortado"><small>{{$nifs[cliente.nif]}}</small></h4>-->
                                        <h4 class="nombre_cortado"><small>{{cliente.nomnif}}</small></h4>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <p><small>Vistar entre {{formato(cliente['inicio'])}} y {{formato(cliente['fin'])}}</small></p>    
                                </ion-row>  
                                <ion-row>
                                    <p><small> {{cliente['cnae_group']}} - {{cliente.actividad}}</small></p>
                                </ion-row>  
                            </ion-row>
                        </ion-grid>  
                    </ion-item>
                </ion-list>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>



<script>
    import {  IonContent,IonGrid,  IonRow,IonCol,IonLabel,IonPage, IonBadge,
              IonSegment,IonSegmentButton,IonImg,IonList, IonItem, 
              IonCardTitle, IonCardContent, IonCard, IonSpinner
           } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import { useRoute } from 'vue-router';

    export default defineComponent({
        name: 'VisitsList',
        components: { 

            IonContent,IonGrid,  IonRow,IonCol,IonLabel,IonPage, IonBadge,
              IonSegment,IonSegmentButton,IonImg,IonList, IonItem, CabeceraComponent, 
              IonCardTitle, IonCardContent, IonCard, IonSpinner
            }, 
        setup() {
            const route = useRoute();
            const estado = route.params=== undefined ? 'todas' : route.params.estado  
            window.miconsola(estado)
            return { estado }
        },
        data() {
            return {
                showme :false, 
                search: [], 
                datos: [], 
                filtrados:undefined, 
                tipo: 'todos'
            }
        }, 
        computed :{
                ListUrl: function () {
                    window.miconsola(`/month_visits/${this.$current_user}`)
                    return `/month_visits/${this.$current_user}`
                    }, 
            },
        mounted() {
            window.miconsola(this.estado)
            this.fetchData(this.estado)
            this.showme = true
        },
        methods : {
            async fetchData(status){ 
                const result = await this.$http.get(this.ListUrl) 
                let data  =result.data 
                window.miconsola(this.$hoy, this.$fecha_corte)
                window.miconsola(data)
                data = _.filter(data, (e)=>{
                        return status == 'hechas' 
                            ? e.done!=undefined 
                                : status == 'pendientes' ? e.done == undefined
                                : true
                        })
                _.each(data,(e)=> {
                    e.badge = e.visitas=='Semanal' ? 'danger' 
                        : e.visitas == 'Quincenal' ? 'warning'
                        : 'tertiary' 
                })
                this.datos = _.sortBy(data, (e)=>  {
                    return e.orden && e.fin
                })
                window.miconsola(this.datos)
                window.miconsola(JSON.stringify(this.datos).slice(0,100))
                this.filtrados = _.filter(this.datos, (e)=> {return this.tipo =='todos' ? true : e.visitas == this.tipo})
                this.showme = true
                },
            lanzarBusqueda(ev){
                    const termino =ev.target.value 
                    if (termino.length>=3||termino.length==0) {this.filterData(termino)}   
            },
            segmentChanged(ev) {
                window.miconsola('Segment changed', ev);
                this.tipo = ev.target.value 
            }, 
            formato(e){
                e = typeof(e)=== "string" ? e : e.toString()
                const a =  [e.slice(0,4), e.slice(4,6),e.slice(-2)]
                return `${a[0]}.${a[1]}.${a[2]}`
            }
        },
        watch :{
            tipo: function(newV,oldV) {
                window.miconsola(newV,oldV)
                this.filtrados = _.filter(this.datos, (e)=> {return this.tipo =='todos' ? true : e.visitas == this.tipo})
            }, 
            '$route.params': {
                    handler(newValue) {
                        window.miconsola(newValue)
                        window.miconsola(this.estado)
                        this.showme = false
                        this.fetchData(newValue.estado)
                    },
                    immediate: true,
                }
        } 

        });
</script>

<style scoped>
    ion-row.spin{
        min-height: 300px;
        width: 100%;
    }
    spin {
        height: 80%
    }
    ion-spinner {
        width:90px; 
        height:90px; 
        display: block;
        margin: auto;
    }
    ion-list{
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 12px;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    ion-item {
        --inner-padding-end: 4px !important; 
        --padding-start: 10px !important; 
    }
    ion-row.elemento {
        margin: 10px 0px !important;
    }
   
    ion-grid.sin-borde{
        padding: 0px !important; 
        margin: 0px !important; 
        width: 100% !important;
    }
    span.grande { font-size:larger }
    h4 { margin:0px !important; }
    h3.derecha { text-align: right !important; }
    h5,h6,p {
        margin-top:0px !important;
        margin-bottom:0px !important;
    }
    ion-col.nombre { padding-top:0px !important; }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
     ion-img.etiqueta{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 2;
        max-width: 60px;
        max-height: 60px;
    }
</style>
