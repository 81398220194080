    <template>
    <ion-row>
        <ion-col size="2"  v-if="chartData">
            <ion-card-subtitle  v-if="chartData"><small>{{titulo}}</small></ion-card-subtitle>
        </ion-col>
        <ion-col  size="10"  v-if="chartData">
                <GChart
                v-if="chartData"
                style="height: 40px !important;"
                v-bind:key="clave"
                type="LineChart"
                :data="chartData"
                :options="chartOptions"
                />
        </ion-col>
    </ion-row>

</template>

<script>
    import {  IonRow, IonCol,IonCardSubtitle } from '@ionic/vue';
    import { GChart } from 'vue-google-charts'
    import * as _ from 'underscore';
    import { defineComponent } from 'vue';
    
    export default defineComponent({
            name : "LineChartRunComponent",
            components: { GChart, IonRow, IonCol,IonCardSubtitle}, 
            props: {data: Object, valor: String, unidades:String, titulo: String, divisor: String, clave:String},
            mounted() {
                this.chartData = this.dataTemplate(this.valor,this.divisor, this.unidades)
                this.chartOptions.width = this.cur_width
            },
            computed :{
                cur_width: function () {
                    return window.screen.width*0.784
                    }}, 
            data() {
                return {
                    chartData:undefined,  
                    chartOptions: {
                            titlePosition : 'none',
                            annotations: { alwaysOutside: false}  , 
                            chartArea:{'width':'90%' },                             
                            legend: {position: 'none'},
                            backgroundColor: { fill:'transparent' },
                            height: 40,
                            width: 200,
                            pointVisible:false,
                            pointSize: 4,
                            vAxis:{baselineColor: '#fff', gridlineColor: '#fff',textPosition: 'none'}, 
                            hAxis:{baselineColor: '#fff', gridlineColor: '#fff',textPosition: 'none'}                        
                    }
                    }
            }, 
            methods : {
                numero (n){
                    return [undefined, null, 0,].indexOf(n)!=-1 ? 0 : Math.round(n/1000)
                }, 
                porcentaje(n){
                    return [undefined, null, 0,].indexOf(n)!=-1 ? 0 : Math.round(n,2)},
                entero (n){
                     return [undefined, null, 0,].indexOf(n)!=-1 ? 0 : Math.round(n)
                },
                SufNumero (n){
                    return n==0  ? '-' : n.toLocaleString()+' k€'
                }, 
                SufPorcentaje(n){
                    return  n==0 ? '-' : n.toLocaleString()+'%'},
                SufEntero (n){
                     return n==0  ? '-' : n.toLocaleString()
                },
                
                dataTemplate (sel,divisor,unidades){
                    const transformer = {'miles': this.numero, 'euros': this.entero , 'porcentaje': this.porcentaje}[unidades]
                    const suffix = {'miles': this.SufNumero, 'euros': this.SufEntero , 'porcentaje': this.SufPorcentaje}[unidades]
                    const dt = _.reduce(this.data, (m,v,k)=> {m[k]=Math.floor(transformer(v[sel]/(v[divisor]||1))); return m }, {})
                    const vals = _.values(dt)
                    dt.minimo = Math.floor(_.min(vals)-((_.max(vals)-_.min(vals))/9))
                    dt.maximo = Math.floor(_.max(vals)+((_.max(vals)-_.min(vals))/9))
                    window.miconsola(dt)
                    return [
                            ['', '',  { role: 'annotation' },{'role': 'style'} ],
                            [dt.minimo,  0,      '\n'+suffix(dt.minimo),null],
                            [dt._id,  0,      'Yo', 'point { size: 8; shape-type: circle; fill-color: #CD5C5C; }'],
                            [dt.pdv,  0,      'Pdv', 'point { size: 6; shape-type: circle; fill-color: #e0ac08; }'],
                            [dt.zid,  0,       'Zona', 'point { size: 6; shape-type: circle; fill-color: #5260ff; }'],
                            [dt.GES,  0,      'Ges', 'point { size: 6; shape-type: circle; fill-color: #006400; }'],
                            [dt.maximo,  0,     suffix(dt.maximo),null],
                            ]
            }}
})
</script>

<style scoped>
    div.chart_container {
            height: 40px !important;

    }
        GChart {
            position:absolute; 
            /*height: 100%; */
        }
        ion-card-subtitle {
            text-transform: capitalize !important;
            align-items: center;
        }
        ion-row {
            width:100% !important;
            height:40px !important;
        }
        
        ion-col {
            height: 40px !important;
            padding : 0px!;
            justify-content: center; 
            align-items: center;
            text-align: right;
            margin: auto; 
            }

</style>