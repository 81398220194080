<template>
    <ion-page>
        <CabeceraComponent titulo="Listado de visitas"/>
        <ion-content class="ion-padding">
           <ion-card>
                <ion-card-content mode="md">
                {{$current_user_name}}, genera excels de tus visitas. <br/>
                Lo recibirás en tu correo electrónico.  <br/>
                Incluirá tanto las visitas realizadas como las pendientes o vencidas (no reportadas en su momento).<br/>
                Basta con clickar en uno de los botones adjuntos.
                </ion-card-content>
           </ion-card>
            <hr/>
            <ion-grid>
                <ion-row>
                    <TarjetaHome  titulo="Mes Actual" :detalle="'Visitas de este mes ('+nMesAnterior(0) +')'" 
                        @click="clickar(nMesAnterior(0),nMesAnterior(0))"/>
                </ion-row>
            
                <ion-row padding-top >
                    <TarjetaHome  titulo="Mes Anterior" :detalle="'Visitas del mes anterior ('+nMesAnterior(1)+')'" 
                        @click="clickar(nMesAnterior(1),nMesAnterior(1))"/>
                </ion-row>

                <ion-row>
                    <TarjetaHome  titulo="Próximo mes" :detalle="'Visitas para el mes de ('+nMesAnterior(-1) +')'" 
                        @click="clickar(nMesAnterior(-1),nMesAnterior(-1))"/>
                </ion-row>

                <ion-row>
                    <TarjetaHome titulo="Ultimos 3 meses" :detalle="'Visitas desde '+nMesAnterior(3) +' hasta '+nMesAnterior(0) "
                    @click="clickar(nMesAnterior(3),nMesAnterior(0))"/>
                </ion-row>
        
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonGrid, IonRow,  IonContent, IonCardContent, IonCard
               } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent';
    import TarjetaHome from '@/components/TarjetaHome';
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "Reporting",
            components: {  IonPage, IonGrid, IonRow,  IonContent, IonCardContent,IonCard,
            TarjetaHome,CabeceraComponent
            },
            methods: {
                // eslint-disable-next-line no-unused-vars
                async clickar(inicio, fin) {
                    const url = `/listados/${this.$current_user}/${inicio}/${fin}`
                    console.log(url)
                    const destinatario_nombre = this.$firebase.auth().currentUser.displayName
                    const result = await this.$http.get(url) 
                    const data = result.data
                    if (data.status === true  && data.registros == 0 )
                    {
                        alert(`${destinatario_nombre}, no hay listado de visitas para estas fechas`)
                    }
                    else if (data.status ===true)
                        alert(`Listado enviado!, mira tu correo ${destinatario_nombre}`)
                    else {
                        alert('Ha habido un error, intentalo más adelante, si persiste ponte en contacto con ATI ')
                    }
                }, 
                nMesAnterior(n) {
                    const hoy = new Date()
                    const fecha = new Date(hoy.getFullYear(), hoy.getMonth() - n +1, 1);
                    return parseInt(fecha.toISOString().replace(/-/g,'').slice(0,6))

                }
        }
})
</script>

<style scoped>
        hr { 
            background-color: var(--ion-color-medium);
            border: none;
            }
        .derecha {
            text-align: right !important;
        }
        ion-card{
            margin: 12px 4px;
            border: none !important;
            box-shadow: none !important;
        }        
        ion-card-content {
            padding: 4px;
        }

</style>