<template>
    <ion-header>
        <ion-toolbar  color="primary">
            <ion-buttons slot="start" >
                <ion-menu-button />
            </ion-buttons>
           <ion-title>
                <ion-row>
                    <ion-col size="10"  class="ion-float-left izquierda">
                        {{titulo}}
                    </ion-col>
                    <ion-col size="2"  class="ion-float-right">
                        <ion-note ><small>Sales<sup>+</sup></small></ion-note>
                    </ion-col>
                </ion-row>                
            </ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import {    IonHeader, IonMenuButton, IonTitle, 
                IonToolbar,IonButtons, IonNote , IonCol, IonRow,
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "CabeceraComponent",
        components: {  IonHeader, IonMenuButton, IonTitle, 
        IonToolbar, IonButtons, IonNote, IonCol, IonRow },
        props : ['titulo'] 
    })
</script>

<style scoped>
    ion-note{
        color:whitesmoke;
        }
    ion-title {
        width: 100% !important;
        padding-right: 4px;
        /*padding-left: 14% !important; */
        
    }
    ion-row {
        width: 100% !important; 
    }
    .izquierda {
        text-align:left; 
    }
</style>